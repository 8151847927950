export const loadYtIframeApi = () => {
  const tag = document.createElement("script");

  tag.src = "https://www.youtube.com/iframe_api";
  const firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

export const createYtPlayer = (id, videoId, height) => {
  return new YT.Player(id, {
    height: height,
    width: "100%",
    videoId: videoId,
    events: {
      onReady: onPlayerReady,
    },
  });
};

function onPlayerReady(event) {
  event.target.playVideo();
}