import "core-js/stable";
import $ from "jquery";
import "jquery-touchswipe";
import Cookies from "js-cookie";

import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/scrollspy";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/popover";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/tab";

import { url } from "./config";

import { isNYTerms, goalIt, openModal, closeModal } from "./helpers";
import { loadYtIframeApi, createYtPlayer } from "./video";

$(document).on("click change", "[data-goal]", (event) => {
  const goalId = event.target.dataset.goal;
  if (goalId) goalIt(goalId);
  return true;
});

localStorage.setItem("language", (window.language || "ru").toLowerCase());

const headerElement = document.querySelector(".header");
function checkHeaderScroll() {
  if (window.pageYOffset > 0) {
    if (!headerElement.classList.contains("fixed")) {
      headerElement.classList.add("fixed");
    }
  } else {
    if (headerElement.classList.contains("fixed")) {
      headerElement.classList.remove("fixed");
    }
  }
}

checkHeaderScroll();
window.addEventListener("scroll", checkHeaderScroll);

$("#basicNav").on("show.bs.collapse", () => {
  if (!headerElement.classList.contains("shown")) {
    headerElement.classList.add("shown");
  }
});

$("#basicNav").on("hide.bs.collapse", () => {
  if (headerElement.classList.contains("shown")) {
    headerElement.classList.remove("shown");
    checkHeaderScroll();
  }
});

$(".accordion").on("show.bs.collapse hide.bs.collapse", (e) => {
  if (e.type === "show") {
    $(e.target.parentElement).addClass("active");
  } else {
    $(e.target.parentElement).removeClass("active");
  }
});

if (window.innerWidth <= 425) {
  $("#accordion .card.active .collapse").collapse();
}

$(".carousel").carousel({ interval: false, touch: false });

$(".carousel").swipe({
  swipe: (event, direction) => {
    if (window.innerWidth < 576) {
      if (direction === "left") $(".carousel").carousel("next");
      if (direction === "right") $(".carousel").carousel("prev");
    }
  },
  allowPageScroll: "vertical",
});

$('[data-toggle="popover"]').popover({
  container: ".security-list",
  boundary: "viewport",
});

$("body").on("click", (e) => {
  if (
    $(e.target).data("toggle") !== "popover" &&
    $(e.target).parents(".popover").length === 0
  ) {
    $('[data-toggle="popover"]').popover("hide");
  }
});

$("body").scrollspy({ target: "#basicNav", offset: 100 });

$(".js-scroll-to").on("click", (e) => {
  e.preventDefault();
  const offset = window.innerWidth <= 1024 ? 50 : 100;
  const hash = e.target.getAttribute("href");
  if (hash && document.querySelector(hash)) {
    history.pushState(null, null, `${hash}`);
    $("html, body").animate({ scrollTop: $(hash).offset().top - offset }, 500);
  }
});

if (window.location.hash) {
  const offset = window.innerWidth <= 1024 ? 50 : 100;
  const { hash } = window.location;
  if (hash && document.querySelector(window.location.hash)) {
    $("html, body").animate({ scrollTop: $(hash).offset().top - offset }, 500);
  }
}

$(document).on("click", ".opportunities-list-item", (event) => {
  const hasClass = $(event.target)
    .closest(".opportunities-list-item")
    .hasClass("open");
  $(".opportunities-list-item").removeClass("open");
  if (!hasClass) {
    $(event.target).closest(".opportunities-list-item").addClass("open");
  }
});

if (document.location.hash === "#box") {
  setTimeout(() => {
    $("html, body").animate(
      { scrollTop: $("#register-form").offset().top - 50 },
      500
    );
  }, 300);
}

$("input").on("keydown blur change", (e) => {
  const { value } = e.target;
  const parent = e.target.closest(".form-group");
  if (!parent) {
    return;
  }
  if (parent.classList.contains("register-form-info-item")) {
    return;
  }

  if (e.type === "blur" || e.target.checkValidity() === true) {
    if (value && value.length > 3) {
      if (!parent.classList.contains("was-validated")) {
        parent.classList.add("was-validated");
      }
    } else if (parent.classList.contains("was-validated")) {
      parent.classList.remove("was-validated");
    }
  }
});

const modalElement = document.querySelector("#js-modal");

if (document.querySelector(".js-modal-open-link")) {
  document
    .querySelector(".js-modal-open-link")
    .addEventListener("click", () => {
      openModal(modalElement);
    });
}

if (document.querySelector(".js-modal-close-link")) {
  document.querySelectorAll(".js-modal-close-link").forEach((elem) => {
    elem.addEventListener("click", (e) => {
      closeModal(e.target.closest(".modal"));
    });
  });
}

$(document).on("click", ".modal.open", (event) => {
  if (document.querySelector("#js-modal-ny.open")) {
    if (event.target.tagName === "A" && event.target.target === "_self") {
      closeModal($(event.target).closest(".modal"));
    }
    return;
  }
  if (!$(event.target).closest(".modal-inner").length) {
    closeModal($(event.target).closest(".modal"));
  }
});

const NYmodal = document.querySelector("#js-modal-ny");

if (NYmodal && isNYTerms()) {
  setTimeout(() => {
    openModal(NYmodal);
    Cookies.set("ny_modal_opened", new Date());
  }, 5000);
}

if(window.location.search === '?utm_source=email&utm_campaign=catalogseller') {
  openModal(modalElement);
}

$("#feedback-form").submit((event) => {
  event.preventDefault();
  const { goalId } = event.target.dataset;
  if (goalId) goalIt(goalId);

  event.target.classList.add("was-validated");
  if (event.target.checkValidity() === true) {
    const data = {
      email: event.target.email.value,
      body: encodeURIComponent(
        `Запрос обратной связи от ${event.target.elements.email.value}\n${event.target.elements.body.value}`
      ),
    };
    $.post({
      url: `${url}/feedback/landing/`,
      data,
      success: () => {
        event.target.classList.remove("was-validated");
        modalElement.classList.remove("open");
        event.target.reset();
        document.querySelector("#js-modal-success").classList.add("open");
      },
      fail: () => {
        console.log("fail");
        modalElement.classList.remove("open");
        document.querySelector("#js-modal-error").classList.add("open");
      },
    });
  }
});

/* скачать расширение */
const downloadLinks = {
  chrome:
    "https://chrome.google.com/webstore/detail/webpass-%D0%BC%D0%B5%D0%BD%D0%B5%D0%B4%D0%B6%D0%B5%D1%80-%D0%BF%D0%B0%D1%80%D0%BE%D0%BB%D0%B5%D0%B9/hknhddppkpijgnecbngnkfnahmfjheib",
  yandex:
    "https://chrome.google.com/webstore/detail/webpass-%D0%BC%D0%B5%D0%BD%D0%B5%D0%B4%D0%B6%D0%B5%D1%80-%D0%BF%D0%B0%D1%80%D0%BE%D0%BB%D0%B5%D0%B9/hknhddppkpijgnecbngnkfnahmfjheib",
  opera: "http://google.com",
};

function changeLink(target) {
  if (target) {
    $(target.closest(".extension-icons")).children().removeClass("active");
    target.classList.add("active");
    target.closest(".extension-download").querySelector(".btn").href =
      downloadLinks[target.dataset.browser];
  }
}

if (document.querySelector(".extension-download")) {
  document.querySelectorAll(".extension-download").forEach(function (item) {
    changeLink(item.querySelector(".extension-icons-item"));
  });
  $(document).on("click", ".extension-icons-item", (e) => {
    if (e.currentTarget.dataset && e.currentTarget.dataset.browser) {
      changeLink(e.currentTarget);
    }
  });
}

function setLocale(language) {
  window.localStorage.language = language === "ua" ? "uk" : language;
}
window.setLocale = setLocale;

const languageSelectItems = document.querySelectorAll(".lang-menu-item");
const languageSelectForm = document.getElementById("language-change");

if (languageSelectItems.length && languageSelectForm) {
  const $formLanguageField = document.getElementById("language-value");
  languageSelectItems.forEach(function (item) {
    item.addEventListener("click", function (e) {
      e.preventDefault();

      var language = this.dataset.language;

      $formLanguageField && ($formLanguageField.value = language);

      languageSelectForm.submit();
    });
  });
}

const $playVideoButton = document.querySelector(".js-play-video");
let isYtApiLoaded = false;

if ($playVideoButton) {
  if (!isYtApiLoaded) loadYtIframeApi();
}

window.onYouTubeIframeAPIReady = function () {
  $playVideoButton.addEventListener("click", function (e) {
    const videoId = this.dataset.video;
    const videoHolder = document.querySelector(".what-is__video-button");

    if (videoId) {
      const player = createYtPlayer(
        "video-container",
        videoId,
        videoHolder.clientHeight
      );

      if (videoHolder) {
        videoHolder.style.display = "none";
      }
    }
  });

  isYtApiLoaded = true;
};